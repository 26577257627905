import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertsService } from 'angular-alert-module';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  email: any;
  password: any;
  constructor(
    private router: Router,
    private userApi: UserService,
    private spinner: NgxSpinnerService,
    private alert: AlertsService
  ) {
    localStorage.clear();
  }
  login() {
    if (this.email && this.password) {
      this.spinner.show();
      const data = {
        username: this.email,
        password: this.password
      };
      this.userApi.login(data).subscribe((res: any) => {
        console.log(res);
        localStorage.setItem('token', res.token);
        localStorage.setItem('userid', res.user.id);
        if (res.user.type_id === 3) {
          this.router.navigateByUrl('/dashboard');
        } else {
          this.alert.setMessage('Not Authorized User', 'error');
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        console.log(err);
        if (err.status === 400) {
          this.alert.setMessage(err.error.msg, 'error');
        }

        if (err.status === 500) {
          this.alert.setMessage('Internal Serve Error', 'error');
        }
        if (err.status === 401) {
          this.alert.setMessage('unathorized attampt', 'error');
        }

        if (err.status === 0) {
          this.alert.setMessage('Can\'t connect to serve', 'error');
        }
      });
    } else {
      this.alert.setMessage('Username and apssword is required', 'error');
    }

  }
}
