import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      // {
      //   path: 'charts',
      //   loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      // },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      // {
      //   path: 'widgets',
      //   loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      // },
      {
        path: 'category',
        loadChildren: () => import('./views/category/category.module').then(m => m.CategoryModule)
      }, {
        path: 'categories/:c_id',
        loadChildren: () => import('./views/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'editcategory/:c_id',
        loadChildren: () => import('./views/editcategory/editcategory.module').then(m => m.EditcategoryModule)
      },
      {
        path: 'playlist/:c_id',
        loadChildren: () => import('./views/playlist/playlist.module').then(m => m.PlaylistModule)
      },
      {
        path: 'editplaylist/:p_id',
        loadChildren: () => import('./views/editplaylist/editplaylist.module').then(m => m.EditplaylistModule)
      },
      {
        path: 'goals',
        loadChildren: () => import('./views/goals/goals.module').then(m => m.GoalsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'videos/:p_id/:c_id',
        loadChildren: () => import('./views/videos/videos.module').then(m => m.VideosModule)
      },
      {
        path: 'challenge',
        loadChildren: () => import('./views/category/category.module').then(m => m.CategoryModule),
        data: { type: 'challenge' }
      },
      {
        path: 'challengelist/:ch_id',
        loadChildren: () => import('./views/challengelist/challengelist.module').then(m => m.ChallengelistModule)
      },
      {
        path: 'notification',
        loadChildren: () => import('./views/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'reviews',
        loadChildren: () => import('./views/reviews/reviews.module').then(m => m.ReviewsComponentModule)
      },
      {
        path: 'iap',
        loadChildren: () => import('./views/iap/iap.module').then(m => m.IapComponentModule)
      },
      {
        path: 'iap-codes',
        loadChildren: () => import('./views/iap-codes/iap-codes.module').then(m => m.IapCodesComponentModule)
      },
      {
        path: 'diet',
        loadChildren: () => import('./views/diet/diet.module').then(m => m.DietComponentModule)
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
