import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient
  ) { }

  login(data) {
    return this.http.post(environment.baseUrl + 'public/login', {
      email: data.username,
      password: data.password
    });
  }

  validateToken(token) {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + token);
    return this.http.post(environment.baseUrl + 'admin/user/validate', { token }, { headers: headers });
  }

  uploadFile(files: File[]) {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('file', f));
    return this.http.post(environment.baseUrl + 'upload_file', formData);
  }

  getAllUser(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get(environment.baseUrl + 'admin/users', httpOptions);
  }

  updateUser(token, id, update) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put(environment.baseUrl + 'admin/user/update', {
      id,
      data: update
    }, httpOptions);
  }

  deleteUser(token: string, id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.delete(environment.baseUrl + 'admin/users/' + id, httpOptions);
  }

  getUserById(token, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get(environment.baseUrl + 'admin/user?id=' + id, httpOptions);
  }

  SendNotification(token, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.post(environment.baseUrl + 'admin/user/sendNotification', data, httpOptions);
  }
}
